<template>
  <div>
    <el-dialog :visible="dialogFormVisible" @close="closeDialog('ruleForm')" destroy-on-close>
      <div slot="title" class="left_top">
        <div class="left_icon">
          <span></span>
          <span>{{ title }}</span>
        </div>
      </div>
      <el-form
        width="900px"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="ruleForm"
        label-width="110px"
      >
        <el-card class="box-card">
          <div class="left_top" style="margin:10px">
            <div class="left_icon">
              <span></span>
              <span>基础功能</span>
            </div>
          </div>

          <el-form-item label="资源类型" prop="resourceType" style="text-align:left">
            <el-radio-group size="small" v-model="ruleForm.resourceType">
              <el-radio-button label="1">入口权限</el-radio-button>
              <el-radio-button label="2">功能权限</el-radio-button>
              <el-radio-button label="3">数据权限</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="功能名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>

          <el-form-item label="功能对应接口" prop="api" v-if="ruleForm.resourceType !== '1'">
            <el-select
              style="width:100%"
              v-model="ruleForm.api"
              filterable
              placeholder="请选择"
              @change="apiChange"
            >
              <el-option v-for="item in apiOptions" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="控制标识" prop="action">
            <el-input v-model="ruleForm.action"></el-input>
          </el-form-item>

          <el-form-item label="字段权限" prop="field" v-if="ruleForm.resourceType !== '1'">
            <el-select
              v-model="ruleForm.field"
              multiple
              placeholder="请选择"
              style="width: 100%"
              filterable
              allow-create
              @change="fieldsChange"
            >
              <el-option label="$all" value="$all"></el-option>
              <el-option v-for="item in fieldsList" :key="item" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <div style="display: flex; justify-content: space-between">
            <el-form-item label="是否禁用" prop="disable">
              <el-switch v-model="ruleForm.disable" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
            <!-- <el-form-item label="是否可配置" prop="custom">
              <el-switch
                v-model="ruleForm.custom"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </el-form-item>-->
            <el-form-item label="是否公共组件" prop="common">
              <el-switch v-model="ruleForm.common" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </div>
          <el-form-item label="描述" prop="desc">
            <el-input type="textarea" v-model="ruleForm.desc" maxlength="500" show-word-limit></el-input>
          </el-form-item>
        </el-card>

        <el-card class="box-card" v-if="ruleForm.resourceType === '3'">
          <div class="left_top" style="margin:10px">
            <div class="left_icon">
              <span></span>
              <span>数据功能</span>
            </div>
          </div>
          <ScopeVue
            @childByValue="childByValue"
            :inputName="fieldsList"
            :spoceList="ruleForm.scope"
          ></ScopeVue>
        </el-card>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align:center">
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        <el-button @click="closeDialog('ruleForm')">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as _ from 'lodash';
import { api } from '/src/api/base';
export const roleResourceNameCodes = api()('/roleResource.nameCodes.json');
export const roleResourceAdd = api()('/roleResource.add.json');
export const roleResourceEdit = api()('/roleResource.edit.json');
export const roleResourceBaseObjects = api()('/roleResource.baseObjects.json');
export const roleResourceOne = api()('/roleResource.one.json');

export const resourceAdd = api()('/resource.add.json');
export const resourceEdit = api()('/resource.edit.json');
export const resourceConfigData = api()('/resource.config.data.json');

export default {
  components: {
    ScopeVue: () => import('./scope.vue')
  },
  props: {
    dialogFormVisible: Boolean,
    sourceType: String,
    data: Object
  },
  data () {
    return {
      apiOptions: [],
      title: '新增资源',
      type: 'add',
      code: '',
      ruleForm: {
        scope: [],
        name: '',
        resourceType: '',
        desc: '',
        api: '',
        action: '',
        field: '',
        disable: false,
        common: false
      },
      rules: {
        name: [{ required: true, message: '请输入功能名称', trigger: 'blur' }],
        api: [
          { required: false, message: '请选择功能对应接口', trigger: 'change' }
        ],
        scope: [],
        desc: [],
        type: [],
        action: [
          { required: false, message: '请输入控制标识', trigger: 'blur' }
        ],
        field: [],
        disable: [],
        common: []
      },
      typeList: [],
      apiList: [],
      fieldsList: [],
      loading: false,
      oldField: []
    };
  },
  async created () {
    console.log(111);
    const apiFields = await resourceConfigData();
    this.apiOptions = apiFields.apis;
    this.fieldsList = apiFields.entityFields;

    this.title = this.sourceType === 'add' ? '新增资源' : '编辑资源';
    if (this.sourceType === 'add') {
      this.ruleForm.name = `${this.data.fullpath}/节点名称`;
    }
    if (this.sourceType === 'edit') {
      this.code = this.data.data.code;
      Object.keys(this.ruleForm).forEach(it => {
        this.ruleForm[it] = this.data.data[it];
        if (it === 'scope') {
          this.ruleForm[it] = JSON.parse(this.data.data[it] || '[]');
        }
        if (it === 'field') {
          this.ruleForm[it] = this.data.data[it] && this.data.data[it].split(',') || []
        }
      });
    }
  },
  methods: {
    apiChange () {
      if (this.sourceType === 'add') {
        this.ruleForm.action = _.camelCase(
          this.ruleForm.api.replace('.json', '')
        );
      }
    },
    fieldsChange () {
      if (
        this.oldField.indexOf('$all') === -1 &&
        this.ruleForm.field.indexOf('$all') > -1
      ) {
        this.ruleForm.field = ['$all'];
      }
      if (
        this.oldField.indexOf('$all') > -1 &&
        this.ruleForm.field.indexOf('$all') > -1 &&
        this.ruleForm.field.length > 1
      ) {
        this.ruleForm.field = this.ruleForm.field.filter(it => it !== '$all');
      }
      this.oldField = _.castArray(this.ruleForm.field);
    },
    // 获取资源信息
    async getRoleResourceOne () {
      const result = await roleResourceOne({ code: this.code });
      result.fields = result.fields ? result.fields.split(',') : [];
      result.scope = JSON.parse(result.scope);
      Object.keys(this.ruleForm).forEach(key => {
        this.ruleForm[key] = result[key];
      });
    },
    childByValue (childByValue) {
      this.ruleForm.scope = childByValue;
    },
    closeDialog (form) {
      this.$refs[form].resetFields();
      this.$emit('update:dialogFormVisible', false);
    },
    submitForm (form) {
      this.$refs[form].validate(async valid => {
        if (valid) {
          const params = {
            ...this.ruleForm,
            scope: JSON.stringify(this.ruleForm.scope),
            field: (this.ruleForm.field || []).join(',')
          };
          console.log(params, this.sourceType);
          if (this.sourceType === 'add') {
            const res = await resourceAdd(params);
            this.$message.success(`资源 ${this.ruleForm.name} 添加成功！`);
            this.$emit('close', res);
          } else {
            const res = await resourceEdit({
              code: this.code,
              ...params
            });
            this.$message.success(`资源 ${this.code} 编辑成功！`);
            this.$emit('close', res);
          }
          this.closeDialog(form);
        }
      });
    }
  }
};
</script>

<style>
.content {
  margin: 0 auto;
}
</style>
